<template>
  <SwiperComponent
    :modules="modules"
    :scrollbar="{
      enabled: true,
      snapOnRelease: false,
      draggable: true,
      horizontalClass: 'swiper-scrollbar',
    }"
    :navigation="{
      prevEl: prevEl ?? null,
      nextEl: nextEl ?? null, // swiper always needs null on elements to correctly work. undefined does not work.
      disabledClass: 'navigation-button--disabled',
    }"
    :space-between="24"
    :free-mode="true"
    slides-per-view="auto"
  >
    <SwiperSlide v-for="(item, index) in items" :key="index">
      <slot name="item" v-bind="item" />
    </SwiperSlide>
  </SwiperComponent>
  <div class="flex justify-end">
    <div class="buttons pt-16">
      <button
        ref="prevEl"
        class="navigation-button-prev m-0 px-8"
        aria-label="Previous slide"
      >
        <SpriteSymbol
          name="arrow-left"
          class="h-24 w-24 scale-110 text-grey-dark-01 hover:text-black"
        />
      </button>
      <button
        ref="nextEl"
        class="navigation-button-next m-0 px-8"
        aria-label="Next slide"
      >
        <SpriteSymbol
          name="arrow-right"
          class="h-24 w-24 scale-110 text-grey-dark-01 hover:text-black"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends object">
import { Navigation, FreeMode, Scrollbar } from 'swiper/modules'
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/vue'

interface Props {
  items: T[]
}

defineProps<Props>()

const modules = [Navigation, FreeMode, Scrollbar]

const prevEl = ref<HTMLButtonElement>()
const nextEl = ref<HTMLButtonElement>()
</script>

<style lang="scss" scoped>
// we need to import 'swiper/css' directly and with :deep, because it is a top level element in this component.
@import 'swiper/scss';

:deep() {
  @import 'swiper/scss';
  @import 'swiper/scss/navigation';
  @import 'swiper/scss/scrollbar';
}

.swiper {
  :deep(.swiper-slide) {
    flex: 0;
    margin-bottom: 48px;
  }

  :deep(.swiper-scrollbar) {
    @apply w-full bg-grey-light-01;

    border-radius: 0;
    height: 2px;
    left: 0;
    width: 100%;

    .swiper-scrollbar-drag {
      @apply bg-black;

      border-radius: 0;
      height: 2px;
    }
  }
}

.navigation-button {
  &--disabled svg {
    @apply text-grey-mid;
    @apply hover:text-grey-mid;
  }
}

.buttons {
  padding-right: 48px;

  @media screen and (max-width: 500px) {
    padding-right: 16px;
  }
}
</style>
